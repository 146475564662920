.homepage > .images_main {
  display: block;
  width: 100%;
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 8px;
}
.homepage > .tips {
  display: block;
  background: linear-gradient(45deg, var(--color-4), var(--color-6));
  max-width: 600px;
  width: 87%;
  margin-top: 0px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.116) 0px 25px 20px -20px;
  position: relative;
  border-radius: 7px;
  border-width: 1px;
  border-style: dashed;
  border-color: var(--color-3);
}

.homepage > .tips > h3 {
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 16px;
  color: var(--color-8);
}

.homepage > .tips > p {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 12px;
  background: var(--color-7);
  border-radius: 5px;
  padding: 5px;
  color: var(--whiteColor);
}

.homepage > .tips > .tips_title {
  position: absolute;
  right: -5px;
  top: -10px;
  font-size: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: var(--color-7);
  color: var(--whiteColor);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.homepage > .slider-muscles {
  max-width: 700px;
  width: 90%;
  margin-top: 10px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}

.homepage > .slider-muscles {
  /* position: relative; */
}

.homepage > .slider-muscles .slider-item {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* .homepage>.slider-muscles .alice-carousel__stage li {
    margin-right: 5px;
    margin-left: 5px;
} */

.homepage > .slider-muscles .slider_muscles_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 12px;
}

.homepage > .slider-muscles .slider_muscles_names {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  margin: 0;
  font-size: 10px;
  text-wrap: nowrap;
  color: var(--color-4);
  background-color: var(--box-3);
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 5px;
}

.homepage
  > .slider-muscles
  .alice-carousel__stage
  li:hover
  .slider_muscles_names {
  color: var(--whiteColor);
  background: linear-gradient(45deg, var(--color-4), var(--color-6));
}

.homepage > .menu-list {
  width: 90%;
  max-width: 700px;
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
  grid-template-columns: repeat(2, 1fr);
  /* تحديد عمودين */
  gap: 10px;
  /* المسافة بين العناصر */
  padding: 0;
  list-style: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 10px;
}

.homepage > .menu-list > .menu-item > .menu-link {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-decoration: none;
  color: var(--color-3);
  padding: 10px;
  box-sizing: border-box;
  background: linear-gradient(45deg, var(--color-4), var(--color-6));
  border-radius: 17px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-7);
}

.homepage > .menu-list > .menu-item > .menu-link:hover {
  background: linear-gradient(45deg, var(--color-6), var(--color-4));
}

.homepage > .menu-list > .menu-item > .menu-link:hover > p {
  text-shadow: 2px 2px 10px #000000b1;
}

.homepage > .menu-list > .menu-item > .menu-link > svg {
  color: var(--color-7);
  font-size: 25px;
  margin-left: 10px;
  background-color: var(--box-3);
  border-radius: 7px;
  padding: 5px;
}

.homepage > .menu-list > .menu-item > .menu-link > p {
  color: var(--whiteColor);
  font-size: 15px;
  text-align: center;
}

/* صفحة ادوات النادي GymEquipmentPage  */

#GymEquipmentPage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  width: 90%;
  max-width: 700px;
  text-align: center;
}

#GymEquipmentPage > #box_equipments {
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 0px;
  list-style: none;
  margin-top: 50px;
  margin-bottom: 50px;
  color: var(--color-4);
}

#GymEquipmentPage > #box_equipments > .item_equipments {
  background-color: var(--box-3);
  border-radius: 8px;
  cursor: pointer;
  padding: 10px;
}

#GymEquipmentPage > #box_equipments > .item_equipments > .equipments_images {
  width: 100%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 245px) {
  .homepage > .menu-list > .menu-item > .menu-link > svg {
    display: none;
  }
}

/* صفحة مصطلحات رياضية GlossaryPage  */

/* Container for the entire glossary page */
#glossaryPage {
  padding: 10px;
  max-width: 900px;
  margin: 10px auto;
}

/* Container for the glossary section within the page */
#glossaryPage .glossary {
  background-color: var(--box-3);
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header styling within the glossary section */
#glossaryPage .glossary .header {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 2px solid var(--color-4);
  padding-bottom: 15px;
  margin-bottom: 20px;
}

#glossaryPage .glossary .header h1 {
  font-size: 17px;
  color: var(--color-4);
}

/* Individual term styling within the glossary section */
#glossaryPage .glossary .term {
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  background-color: var(--color-8);
  transition: background-color 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

#glossaryPage .glossary .term h2 {
  font-size: 15px;
  color: var(--color-3);
}

#glossaryPage .glossary .term p {
  font-size: 14px;
  color: var(--color-6);
}
