@font-face {
  font-family: "Cairo-Regular";
  src: url("../../../public/fonts/Cairo-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cairo";
  src: url("../../../public/fonts/Cairo-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Cairo-ExtraBold";
  src: url("../../../public/fonts/Cairo-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  /* قد يتطلب تعيين الوزن برقم معين */
  font-style: normal;
}

* {
  font-family: "Cairo", Arial, sans-serif;
  -webkit-tap-highlight-color: #00000000;
  user-select: none;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ */
  -moz-user-select: none;
  /* Firefox */
}

body {
  background-color: var(--background);
  margin: 0px 0px 0px 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--whiteColor);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  user-select: none;
  /* تمنع تحديد النص */
  -moz-user-select: none;
  /* تمنع تحديد النص على Firefox */
  -webkit-user-select: none;
  /* تمنع تحديد النص على Chrome و Safari و Edge */
  -ms-user-select: none;
  /* تمنع تحديد النص على Internet Explorer / Edge */
}

.title_item {
  color: var(--color-4);
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.LoadingPageSkeleton {
  display: block;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}

input:focus,
textarea:focus,
select:focus,
a:focus {
  outline: none;
}

#App {
  padding-top: 56px;
  padding-bottom: 100px;
  position: relative;
}

#BtTheme {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: fixed;
  bottom: 110px;
  left: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 12px;
  background-color: var(--color-3);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 4px;
  border-radius: 6px;
  opacity: 0.5;
}
