[data-theme="light"] {
  --whiteColor: #ffffff;
  --BlackColor: #000000;
  --background: #eceff4;
  --color-1: #cde8e5;
  --color-2: #eef7ff;
  --color-3: #7ab2b2;
  --color-4: #4d869c;
  --color-5: #b0d8d5;
  --color-6: #5e9e9e;
  --color-7: #3a6b7c;
  --color-8: #e5f7f6;
  --text-h: #27738d;
  --box: #dee2ea;
  --box-2: #dddddd;
  --box-3: #ffffff;
  --box-4: #f9f9f9;
  --border: #dddddd;
  --iconColor: #7ab2b2;
  --input: #ffffff;
  --input-text: #000000;
  --navigationbarBackground: #ffffff;
  --navigationbarColor: #000000;
  --navigationbarColorIcon: #7ab2b2;
  --navigationbarBackgroundHover: #e1f6f6;
  --navigationbarBackgroundHover-2: #c1dfdf;
  --navigationbarBackgroundAfter: #7ab2b244;
}

[data-theme="dark"] {
  --whiteColor: #ffffff;
  --BlackColor: #000000;
  --background: #121212;
  --color-1: #2e3a36;
  --color-2: #1c2a2b;
  --color-3: #4a8e8e;
  --color-4: #2e5c6a;
  --color-5: #4b6d6b;
  --color-6: #4a7a7a;
  --color-7: #2c4e5d;
  --color-8: #1d3a39;
  --text-h: #a0c4c4;
  --box: #2a2a2a;
  --box-2: #3a3a3a;
  --box-3: #1e1e1e;
  --box-4: #2c2c2c;
  --border: #3a3a3a;
  --iconColor: #4a8e8e;
  --input: #121212;
  --input-text: #ffffff;
  --navigationbarBackground: #1e1e1e;
  --navigationbarColor: #ffffff;
  --navigationbarColorIcon: #4a8e8e;
  --navigationbarBackgroundHover: #2a2a2a;
  --navigationbarBackgroundHover-2: #3a3a3a;
  --navigationbarBackgroundAfter: #4a8e8b44;
}
