.muscles_pages {
    position: relative;
    z-index: 1;
}

.muscles_pages_ScreenshotCaptureButton {
    display: block;
    position: fixed;
    border: none;
    text-align: center;
    font-size: 25px;
    left: 10px;
    top: 7px;
    z-index: 10000;
    background-color: #00000000;
    color: var(--whiteColor);
}

.muscles_pages_ScreenshotCaptureButton svg {
    cursor: pointer;
}

.muscles_pages>.muscle_description {
    width: 90%;
    max-width: 600px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    color: var(--color-6);
}

.muscles_pages_images {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    width: 90%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.muscles_pages .muscles_pages_images img {
    display: block;
    width: 80%;
    max-width: 100px;
    margin-right: auto;
    margin-left: auto;
    border-width: 2px;
    border-style: dashed;
    border-color: var(--color-3);
    border-radius: 18px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}


#logo_screen {
    max-width: 50px;
}