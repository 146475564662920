/* <====| صفحة جداول التمرين - WorkoutSchedule |====> */

#WorkoutSchedulePage {
  margin-top: 10px;
  width: 95%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}
#WorkoutSchedulePage > #box_menu {
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 20px;
}

#WorkoutSchedulePage > #box_menu > a {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-decoration: none;
  color: var(--color-3);
  padding: 10px;
  box-sizing: border-box;
  background: linear-gradient(45deg, var(--color-4), var(--color-6));
  border-radius: 17px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-7);
}

#WorkoutSchedulePage > #box_menu > a:hover {
  background: linear-gradient(45deg, var(--color-6), var(--color-4));
}

#WorkoutSchedulePage > #box_menu > a > p {
  color: var(--whiteColor);
  font-size: 15px;
  text-align: center;
}

#WorkoutSchedulePage > #box_menu > a:hover > p {
  text-shadow: 2px 2px 10px #000000b1;
}

#WorkoutSchedulePage > #box_menu > a > svg {
  color: var(--color-7);
  font-size: 25px;
  margin-left: 10px;
  background-color: var(--box-3);
  border-radius: 7px;
  padding: 5px;
}

@media (max-width: 245px) {
  #WorkoutSchedulePage > #box_menu > a > svg {
    display: none;
  }
}

/* <====| صفحة تمارين التحمية - WarmUpExercises |====> */

#WarmUpExercisesPage {
  margin: 10px auto;
  width: 95%;
  max-width: 800px;
  padding: 10px;
}
#WarmUpExercisesPage > #box_steps {
  display: block;
}

#WarmUpExercisesPage > #box_steps > div {
  display: block;
  background-color: var(--box-3);
  margin: 10px auto;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--border);
}

#WarmUpExercisesPage > #box_steps > div > .step_number {
  width: 25px;
  height: 25px;
  background-color: var(--color-5);
  color: var(--whiteColor);
  padding: 10px;
  font-size: 15px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#WarmUpExercisesPage > #box_steps > div:hover > .step_number {
  background-color: var(--color-6);
}

#WarmUpExercisesPage > #box_steps > div > div {
  display: block;
  width: 100%;
  padding: 10px;
  text-align: right;
  margin-top: 0px;
  margin-bottom: 0px;
  box-sizing: border-box;
}

#WarmUpExercisesPage > #box_steps > div > div > p {
  text-align: center;
  color: var(--color-6);
}

#WarmUpExercisesPage > #box_steps > div > div > .images_step {
  margin-left: auto;
  margin-right: auto;
}
#WarmUpExercisesPage > #box_steps > div > div > .box_step_info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
#WarmUpExercisesPage > #box_steps > div > div > .box_step_info > p {
  border: 1px solid var(--border);
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: var(--color-5);
}

#WarmUpExercisesPage > #box_steps > div > div > .box_step_info > p > span {
  color: var(--color-4);
  font-size: 13px;
}

/* <====| صفحة تمارين اول يوم بالنادي - FirstDayGymPage |====> */

#FirstDayGymPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  max-width: 800px;
  margin: 20px auto;
}

#FirstDayGymPage > .workout-list > .workout-item {
  margin-bottom: 25px;
  padding: 15px;
  border-radius: 12px;
  background-color: var(--box-3);
  border: 1px solid var(--border);
  /* transition: transform 0.3s, box-shadow 0.3s; */
}

/* #FirstDayGymPage > .workout-list > .workout-item:hover {
  transform: translateY(-5px);
} */

#FirstDayGymPage > .workout-list > .workout-item > p > span {
  color: #ff5050;
}

#FirstDayGymPage > .workout-list > .workout-item > .workout-image-skeleton {
  margin-bottom: 20px;
  border-radius: 12px;
}

#FirstDayGymPage > .workout-list > .workout-item > .workout-image {
  width: 100%;
  height: 250px;
  border-radius: 12px;
  object-fit: cover;
  border: 1px solid var(--border);
}

#FirstDayGymPage > .workout-list > .workout-item > .workout-title {
  font-size: 1.8rem;
  color: var(--text-h);
  margin: 0 0 10px;
  font-weight: bold;
}

#FirstDayGymPage > .workout-list > .workout-item > .workout-target,
#FirstDayGymPage > .workout-list > .workout-item > .workout-sets,
#FirstDayGymPage > .workout-list > .workout-item > .workout-reps {
  font-size: 1.1rem;
  color: var(--color-6);
  margin: 8px 0;
}

#FirstDayGymPage > .workout-list > .workout-item > .workout-description {
  font-size: 1.1rem;
  color: var(--color-7);
  margin-bottom: 15px;
  line-height: 1.6;
}

#FirstDayGymPage > .workout-list > .workout-item > .workout-video-button {
  display: inline-block;
  margin-top: 15px;
  padding: 12px 24px;
  background-color: var(--color-3);
  color: var(--whiteColor);
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s, transform 0.3s;
}

#FirstDayGymPage > .workout-list > .workout-item > .workout-video-button:hover {
  background-color: var(--color-4);
  transform: scale(1.05);
}





/* <====| صفحة تمارين ثلاثث ايام اسبوعيا - ThreeDaysWorkoutPage |====> */

#ThreeDaysWorkoutPage {
  margin: 20px auto;
  width: 95%;
  max-width: 8000px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}



/* <====| جدول التمارين لكل الصفحات التابعة لـ جدول التمارين |====> */

.scheduleWorkout > #schedule {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.scheduleWorkout > #schedule > thead > tr > th,
.scheduleWorkout > #schedule > tbody > tr > td {
  border: 1px solid var(--border);
  padding: 15px;
  text-align: center;
  font-size: 16px;
}

.scheduleWorkout > #schedule > thead > tr > th {
  background: linear-gradient(45deg, var(--color-4), var(--color-6));
  color: var(--whiteColor);
  font-weight: bold;
}

.scheduleWorkout > #schedule > tbody > tr > td {
  background-color: var(--box-3);
  color: var(--color-6);
}

.scheduleWorkout > #schedule > tbody > tr > td > .details-button {
  background: linear-gradient(45deg, var(--color-4), var(--color-6));
  color: var(--whiteColor);
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.scheduleWorkout > #schedule > tbody > tr > td > .exercise-details {
  text-align: right;
  padding: 5px;
  background-color: var(--box-4);
  border-radius: 5px;
}

.scheduleWorkout > #schedule > tbody > tr > td > .exercise-details > .exercise-item {
  margin: 10px 0;
  padding: 10px;
  background-color: var(--color-8);
  border-radius: 5px;
  border: 1px solid var(--border);
}

.scheduleWorkout > #schedule > tbody > tr > td > .exercise-details > .exercise-item .iconitem{
  font-size: 18px;
  margin-left: 10px;
}

.scheduleWorkout > #schedule > tbody > tr > td > .exercise-details > .exercise-item > h3,
.scheduleWorkout > #schedule > tbody > tr > td > .exercise-details > .exercise-item > p {
  margin: 5px 0;
  color: var(--text-h);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.scheduleWorkout > #schedule > tbody > tr > td > .exercise-details > .exercise-item > h3> .text,
.scheduleWorkout > #schedule > tbody > tr > td > .exercise-details > .exercise-item > p > .text{
  color: var(--color-3);
  margin-right: 5px;
}
.scheduleWorkout > #schedule > tbody > tr > td > .exercise-details > .exercise-item > p > .number {
  color: #ff5050;
  margin-right: 5px;
}

.scheduleWorkout > #schedule > tbody > tr > td > .exercise-details > .exercise-item >button {
  background: linear-gradient(45deg, var(--color-4), var(--color-6));
  color: var(--whiteColor);
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 10px auto;
}